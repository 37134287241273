import React from 'react';
import {CodesForm} from '../../components/Form/CodesForm';
import {useSendCodeForm} from '../../hooks/codes';
import {CodeGroupT} from '../../external_usage';

export const SendCodeForm = ({group}: {group?: CodeGroupT}) => {
  const {values, error, handleChange} = useSendCodeForm();

  return <CodesForm values={values} error={error} handleChange={handleChange} group={group} />;
};
