import {CodeGroupT} from '../external_usage';

export const mobileRoutes = {
  welcome: '/welcome',
  groups: '/m-groups',
  send: '/m-send-code',
  guests: '/m-guest-list',
  guestEdit: '/m-guest-edit',
};

export const getSendLink = (group?: Partial<CodeGroupT>) => mobileRoutes.send + `?groupId=${group?.id}`;
export const getGuestLink = (group?: Partial<CodeGroupT>) => mobileRoutes.guests + `?groupId=${group?.id}`;
export const getEditGuestLink = (groupId?: string | number, guestId?: string | number) =>
  mobileRoutes.guestEdit + `?id=${groupId}&guestId=${guestId}`;
