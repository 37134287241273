import React from 'react';
import {SendCodeStateT} from '../../states/codes';
import styled from 'styled-components';
import {Form, Input} from 'antd';
import {QuantityInput} from '../Common/QuantityInput';
import {CodeGroupT, MAX_CODES_PER_GUEST} from '../../external_usage';

type PropsT = {
  values?: SendCodeStateT;
  handleChange?: (field?: string, value?: string | number) => void;
  error?: string;
  group?: CodeGroupT;
};

export const CodesForm: React.FC<PropsT> = ({values, handleChange, error, group}) => {
  const [form] = Form.useForm();
  const onChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    handleChange?.(e.target.name, e.target.value);
  };
  const changeQuantity = (value: any) => handleChange?.('quantity', value);
  const assigned = group?.codesAssigned || 0;
  const total = group?.codesUploaded || 0;
  const max = total - assigned >= MAX_CODES_PER_GUEST ? MAX_CODES_PER_GUEST : total - assigned;
  const limit = max < 0 ? 0 : max;
  return (
    <FormWrapper>
      <Form form={form} validateTrigger="onChange" initialValues={values}>
        <Form.Item rules={[{required: true, message: 'Name is required!'}]} validateTrigger="onChange" name="name">
          <TextInput value={values?.name} onChange={onChange} placeholder="Enter name here..." name="name" />
        </Form.Item>
        <Form.Item
          required
          validateTrigger="onChange"
          name="email"
          rules={[
            {required: true, message: 'Email Address is required!'},
            {
              type: 'email',
              message: 'Please enter valid email!',
            },
          ]}>
          <TextInput value={values?.email} onChange={onChange} placeholder="Enter email here..." name="email" />
        </Form.Item>
        <QuantityInput max={limit} onChange={changeQuantity} value={values?.quantity} />
      </Form>
      {error && <ErrorLabel>{error}</ErrorLabel>}
    </FormWrapper>
  );
};

const FormWrapper = styled.div``;

const TextInput = styled(Input)`
  height: 38px;
  border-radius: 2px;
`;

const ErrorLabel = styled.div`
  color: ${({theme}) => theme.palette.common.roseRed};
  margin: 4px 0;
`;
