import {Button, ButtonProps} from 'antd';
import React, {PropsWithChildren} from 'react';
import {Link, LinkProps} from 'react-router-dom';
import styled, {css} from 'styled-components';
import {themes} from '../../../../ui-kit/theme/theme';

export const PrimaryButton: React.FC<PropsWithChildren & ButtonProps> = ({children, ...props}) => {
  return <StyledButton {...props}>{children}</StyledButton>;
};

export const PrimaryButtonLink: React.FC<PropsWithChildren & LinkProps> = ({children, ...props}) => {
  return <StyledButtonLink {...props}>{children}</StyledButtonLink>;
};

const styles = css`
  width: 100%;
  height: 44px;
  background: ${({theme}) => {
    if (theme.current === themes.coke) return theme.palette.background.black;
    if (theme.current === themes.lite) return theme.palette.background.black;
    return theme.palette.common.brown;
  }};

  font-size: 14px;
  font-weight: 700;
  line-height: 24px;
  letter-spacing: 0em;
  text-align: center;
  color: #fff;

  border: none;
  border-radius: 6px;
  display: block;

  &:hover,
  &:focus {
    background: ${({theme}) => {
      if (theme.current === themes.coke) return theme.palette.background.black;
      if (theme.current === themes.lite) return theme.palette.background.black;
      return theme.palette.common.brown;
    }};
    color: #fff;
    opacity: 0.9;
  }
  &:disabled {
    background: ${({theme}) => {
      if (theme.current === themes.coke) return theme.palette.background.black;
      if (theme.current === themes.lite) return theme.palette.background.black;
      return theme.palette.common.brown;
    }};
    color: #fff;
    opacity: 0.7;
  }
`;

const StyledButton = styled(Button)`
  ${styles}
`;

const StyledButtonLink = styled(Link)`
  ${styles}
  display: flex;
  align-items: center;
  justify-content: center;
`;
